import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import ArrowIcon from "../../images/chevron-down.svg";
import { usePagination } from "../../hooks/usePagination";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";
import { findNumbersBetweenEllipsis } from "../../utils/findNumbersBetweenEllipsis";
import { PaginationPageType } from "../../utils/baseTypes";
import { LinkNoPrefetch } from "./LinkNoPrefetch";

interface PaginationProps {
  postsPerPage: number;
  postsTotal: number;
  setCurrentPage?: Dispatch<SetStateAction<number>>;
  currentPage: number;
  pageType: PaginationPageType;
}

export const Pagenation = ({
  postsPerPage,
  postsTotal,
  currentPage,
  pageType,
}: PaginationProps) => {
  let numbers;
  let totalPages: number = 0;
  const numbersArr: number[] = [];

  const [direction, setDirection] = useState<number>(1);
  const paginationRef = useRef<HTMLDivElement>(null);

  const paginationRange = usePagination({
    totalPosts: postsTotal,
    postsPerPage,
    siblingCount: 1,
    currentPage,
  });

  const paginationHandler = (pageNumber: number | string) => {
    if (typeof pageNumber === "number") {
      setDirection(Math.sign(currentPage - pageNumber) * -1);
    }
  };

  const numberBetween = findNumbersBetweenEllipsis(paginationRange ?? []);

  if (postsTotal && postsPerPage) {
    totalPages = Math.ceil(postsTotal / postsPerPage);
    for (let i = 1; i <= Math.ceil(postsTotal / postsPerPage); i++) {
      numbersArr.push(i);
    }

    if (numbersArr.length) {
      numbers = (
        <AnimatePresence>
          {paginationRange?.map((number, i) => {
            const isType = number === "..." ? "dots" : "number";

            const isBetween = numberBetween.includes(
              typeof number === "number" ? number : -1
            );

            return (
              <motion.span
                initial={
                  isBetween ? { x: `${100 * direction}%`, opacity: 1 } : {}
                }
                animate={isBetween ? { x: 0, opacity: 1 } : {}}
                exit={{ display: "none" }}
                transition={{ type: "spring" }}
                className={`page-number-cont ${
                  number === currentPage ? "active-page" : ""
                }`}
                key={
                  number !== "..."
                    ? isBetween
                      ? `${currentPage}-${number}`
                      : `page-${number}`
                    : `${i}-dot`
                }
              >
                {isType === "dots" && (
                  <motion.span className={`page-number dots`}></motion.span>
                )}

                {isType === "number" && (
                  <PageLink
                    page={number}
                    pageType={pageType}
                    callback={() => paginationHandler(number)}
                  />
                )}
              </motion.span>
            );
          })}
        </AnimatePresence>
      );
    }
  }

  return (
    <>
      {numbersArr.length > 1 && (
        <motion.div
          ref={paginationRef}
          className="column is-12 pagination-wrapper"
        >
          {currentPage > 1 && (
            <TogglePageLink
              pageType={pageType}
              page={currentPage - 1}
              extraClass="prev"
              callback={() => paginationHandler(currentPage - 1)}
            />
          )}
          {numbers}
          {currentPage < totalPages && (
            <TogglePageLink
              pageType={pageType}
              page={currentPage + 1}
              extraClass="next"
              callback={() => paginationHandler(currentPage + 1)}
            />
          )}
        </motion.div>
      )}
    </>
  );
};

interface PageLinkProps {
  page: string | number;
  pageType: PaginationPageType;
  callback?: Function;
}

export const PageLink = ({
  page,
  pageType,
  callback = () => {},
}: PageLinkProps) => {
  const { query } = useRouter();

  const dynamicRoute = query["dynamicRoute"]
    ? (query["dynamicRoute"] as string[])
    : [];

  const letter = query["letter"] ? query["letter"] : null;
  const path = dynamicRoute.join("/");

  return (
    <LinkNoPrefetch
      href={`/${path}?pt=${pageType}&page=${page}${
        letter ? `&letter=${letter}` : ""
      }`}
      as={`/${path}/?page=${page}&pt=${pageType}${
        letter ? `&letter=${letter}` : ""
      }`}
      shallow
      className={`page-number number`}
      scroll={false}
      onClick={() => callback()}
    >
      {page}
    </LinkNoPrefetch>
  );
};

interface TogglePageLinkProp {
  page: string | number;
  pageType: PaginationPageType;
  extraClass?: string;
  callback?: Function;
}

export const TogglePageLink = ({
  page,
  pageType,
  extraClass = "",
  callback = () => {},
}: TogglePageLinkProp) => {
  const { query } = useRouter();

  const dynamicRoute = query["dynamicRoute"]
    ? (query["dynamicRoute"] as string[])
    : [];
  const path = dynamicRoute.join("/");
  const letter = query["letter"] ? query["letter"] : null;

  return (
    <LinkNoPrefetch
      href={`/${path}?pt=${pageType}&page=${page}${
        letter ? `&letter=${letter}` : ""
      }`}
      as={`/${path}/?page=${page}&pt=${pageType}${
        letter ? `&letter=${letter}` : ""
      }`}
      shallow
      className={extraClass}
      aria-label={extraClass}
      scroll={false}
      onClick={() => callback()}
    >
      <ArrowIcon />
    </LinkNoPrefetch>
  );
};
